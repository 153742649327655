import { render, staticRenderFns } from "./moXun.vue?vue&type=template&id=12112051&scoped=true"
import script from "./moXun.vue?vue&type=script&lang=js"
export * from "./moXun.vue?vue&type=script&lang=js"
import style0 from "./moXun.vue?vue&type=style&index=0&id=12112051&prod&lang=less&scoped=true"
import style1 from "./moXun.vue?vue&type=style&index=1&id=12112051&prod&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12112051",
  null
  
)

export default component.exports